import {useStaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import { Container} from "react-bootstrap"
import { Helmet } from "react-helmet";

const Sitemap = () => {
    
    const data=useStaticQuery(graphql`
    query  {
        glstrapi {
          socialLink(publicationState: LIVE) {
            primary_menu {
              id
              menu_items {
                custom_link
                label
                link_type
                menu_item {
                  slug
                  label
                }
              }
            }
          }
        }
      }
    `)

    var menu_data = data?.glstrapi?.socialLink?.primary_menu;
  return (
   <Layout layout="content_only_template">   
          <Helmet
          bodyAttributes={{
            class: `templates-sitemap-template`,
          }}
        />
        <Container>
          <div className='sitemap-component'>
            <h1 className='sitemap-title'>Sitemap</h1>
            <div className='sitemap-list'>
              
            {
                menu_data?.map(data=>(
                   <ul >
                      <li>
                        <Link to={`/${data.menu_items.menu_item?.slug?
                          data.menu_items.menu_item.slug:
                          data.menu_items.custom_link}/`}>
                            {data.menu_items.label}
                        </Link>
                        
                      </li>
                   </ul>
                ))
            }
            </div>
          </div>
        </Container>    
    </Layout>
  )
}

export default Sitemap